var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticStyle: { "text-align": "center" } }, [
        _vm._v(" TODO - 메디컬 트렌드 퀴즈 퍼블리싱 적용 예정 "),
        _c("br"),
        _c(
          "button",
          {
            staticStyle: {
              background: "#3F74F4",
              border: "1px solid",
              "min-width": "180px",
              color: "#FFFFFF",
              height: "40px",
              "margin-top": "15px",
            },
            attrs: { disabled: _vm.completeEvent },
            on: { click: _vm.saveEventHistory },
          },
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm.completeEvent
                    ? "참여 완료하신 이벤트 입니다"
                    : "퀴즈 이벤트 참여"
                ) +
                " "
            ),
          ]
        ),
      ]),
      _c("alert-dialog", { attrs: { options: _vm.alertProps } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }