<template>
  <div>
    <div style="text-align: center;">
      TODO - 메디컬 트렌드 퀴즈 퍼블리싱 적용 예정
      <br>
      <button
        style="background: #3F74F4;; border: 1px solid; min-width: 180px; color:#FFFFFF; height: 40px; margin-top: 15px;"
        :disabled="completeEvent"
        @click="saveEventHistory"
      >
        {{ completeEvent ? '참여 완료하신 이벤트 입니다' : '퀴즈 이벤트 참여' }}
      </button>
    </div>

    <alert-dialog :options="alertProps" />
  </div>
</template>

<script>
import axios from '@axios'
import { mapGetters } from 'vuex'
import { changeUserPoint } from '@/auth/utils'
import { setStatistics } from '@/common/statistics/service'

export default {
  props: {
    eventId: {
      type: Number,
      required: true,
    },
    contentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      completeEvent: false
    }
  },
  computed: {
    ...mapGetters({
      userName: 'account/getName',
    }),
  },
  created() {
    this.fetchEventHistory()
  },
  methods: {
    fetchEventHistory() {
      axios.get(`/fu/event/history/${this.eventId}`)
        .then(rs => {
          if (rs.data) this.completeEvent = true
          else this.completeEvent = false
        })
        .catch(() => {
        })
    },
    saveEventHistory() {
      if (this.userName) {
        this.createEventHistory()
      } else {
        this.redirectLogin()
      }
    },
    createEventHistory() {
      axios.post('/fu/event/history', {
        EventId: this.eventId,
        ContentId: this.contentId,
        Condition_1: ''
      })
        .then(rs => {
          this.showAlertDialog('이벤트 참여 하였습니다.', () => {
            this.completeEvent = true

            setStatistics(121, this.eventId)

            changeUserPoint(rs.data)
          })
        })
        .catch(() => {
          this.showAlertDialog('이벤트 참여 중 오류가 발생했습니다.')
        })
    },
    redirectLogin() {
      this.$router.push({ name: 'login-main', query: { redirect: 'app-medical-trend-main' }, params: { id: this.contentId } }).catch(() => {})
    },
  }
}
</script>
